import auth0 from "auth0-js"
import { navigate } from "gatsby"
import createUserRecord from "../bssr-api/auth0/create-user"

export const isBrowser = typeof window !== "undefined"

export const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_DOMAIN,
      clientID: process.env.GATSBY_AUTH0_CLIENTID,
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
      responseType: "token id_token",
      scope: "openid profile email",
    })
  : {}

export function authorize(connection) {
  auth.authorize({
    connection,
  })
}

export function login({ username, password, errorHandler = undefined }) {
  if (!isBrowser) {
    return
  }

  return auth.login(
    {
      realm: "Username-Password-Authentication",
      username,
      password,
    },
    (res) => {
      if (res.error) {
        if (errorHandler) {
          errorHandler(res)
        }
      }
    }
  )
}

export async function signUp({
  connection,
  email,
  password,
  given_name,
  family_name,
}) {
  if (!isBrowser) {
    return
  }

  if (connection) {
    return auth.authorize({ connection }, (res) => {
      if (res.error) {
        console.error("there was an error", res)
      }
    })
  }

  const config = {
    email,
    password,
    given_name,
    family_name,
  }

  const response = await createUserRecord(config)
  return { ...response }
}

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export function isAuthenticated() {
  if (!isBrowser) {
    return
  }

  return localStorage.getItem("isLoggedIn") === "true"
}

const setSession =
  (cb = () => {}) =>
  (err, authResult) => {
    if (err) {
      if (isBrowser) {
        navigate("/")
      }

      cb(err)
      return
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
      // Set an expires at value
      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()

      // Update values stored in memory (tokens object)
      tokens.accessToken = authResult.accessToken
      tokens.idToken = authResult.idToken
      tokens.expiresAt = expiresAt

      // Update user object in memory
      user = authResult.idTokenPayload

      if (isBrowser) {
        if (
          !localStorage.getItem("isLoggedIn") ||
          localStorage.getItem("isLoggedIn") === "false"
        ) {
          navigate("/account")
        }

        // Tell the browser that the user has logged in
        localStorage.setItem("isLoggedIn", true)
        // localStorage.setItem("user", JSON.stringify(user))
      }

      cb()
    }
  }

export function handleAuthentication() {
  if (!isBrowser) {
    return
  }

  auth.parseHash(setSession())
}

export function getProfile() {
  return user
}

// Keep an eye on this..
// I think that if isAuth is false then we should logout...
export function checkSession(callback) {
  if (!isAuthenticated()) return callback()
  auth.checkSession({}, setSession(callback))
}

export function logout() {
  if (isBrowser) {
    localStorage.setItem("isLoggedIn", false)
  }

  auth.logout({
    returnTo: window.location.origin,
  })
}
